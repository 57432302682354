import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueMasonry from 'vue-masonry-css';  //  Required for VxtMasonryGrid

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(VueMasonry);  // Required for VxtMasonryGrid

Vue.config.productionTip = false

require('./assets/css/wrapped-chunk-vendors.scss')
require('./assets/css/modified-bootstrap.scss')
require('./assets/css/main.scss')

new Vue({
  render: h => h(App),
}).$mount('#app')
