<template>
  <div class="bloxmatch-content">
    <div class="bloxmatch-page" v-bind:class="{'loading': showLoader}">

      <div class="search-header">

        <div class="search-header-medium">
          <div class="single-row-header">
            <div class='home-container'>
              <div v-if="showLoader" class="spinner-container"><b-spinner class="spinner"></b-spinner></div>
              <div v-else-if="!onMainPage" class="home-icon" @click="backToMain()"><font-awesome-icon :icon="['far', 'arrow-alt-circle-left']" class="fa-2x"></font-awesome-icon></div>

            </div>
            <b-input-group class="search-input-group text-center">
              <b-input-group-append><div id="search-button" @click="submitNewQuery(false)"><img src="../assets/img/magnifier.png" /></div></b-input-group-append>
              <b-form-input v-model="query" type="text" autocomplete="off" :placeholder="labels[lang].inputPlaceholder" class="input-field" @keydown.enter.native="submitNewQuery(false)"></b-form-input>
              <b-input-group-append class="filters-container">
                <b-form-select class="filters" v-model="selectedSector" :options="sectors" @change="submitNewQuery(true)"></b-form-select>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="residents-filter">
            <b-form-checkbox v-model="onlyResidents" switch size="md">Show only Bloxhub residents</b-form-checkbox>
          </div>
        </div>

        <div class="search-header-small">
          <div class="multi-row-header">
            <div class='home-container'>
              <div v-if="showLoader" class="spinner-container"><b-spinner class="spinner"></b-spinner></div>
              <div v-else-if="!onMainPage" class="home-icon" @click="backToMain()"><font-awesome-icon :icon="['far', 'arrow-alt-circle-left']" class="fa-2x"></font-awesome-icon></div>
            </div>
            <b-input-group class="search-input-group text-center">
              <b-input-group-append><div id="search-button" @click="submitNewQuery(false)"><img src="../assets/img/magnifier.png" /></div></b-input-group-append>
              <b-form-input v-model="query" type="text" autocomplete="off" :placeholder="labels[lang].inputPlaceholder" class="input-field" @keydown.enter.native="submitNewQuery(false)"></b-form-input>
            </b-input-group>
            <b-input-group-append class="filters-container mt-1">
              <b-form-select class="filters" v-model="selectedSector" :options="sectors" @change="submitNewQuery(true)"></b-form-select>
            </b-input-group-append>
          </div>
          <div class="residents-filter">
            <b-form-checkbox v-model="onlyResidents" switch size="md">Show only Bloxhub residents</b-form-checkbox>
          </div>
        </div>

      </div>

      <div v-if="showInfoUnableToConnect" class="alert alert-danger">{{ labels[lang].notification_unable_to_connect }}</div>
      <div v-else-if="showInfoEmptyQuery" class="alert alert-warning">{{ labels[lang].notification_empty_query }}</div>
      <div v-else-if="showInfoNoResults" class="alert alert-warning">{{ labels[lang].notification_no_results }}</div>

      <div v-bind:class="{'all-results-hidden': !onMainPage}">
        <div v-for="sector in sectors" class="sector">
          <div v-if="sector !== ALL_SECTORS_LABEL && (selectedSector === ALL_SECTORS_LABEL || selectedSector === sector) && getFilteredCompanies(companies[sector]).length > 0">
            <h2>{{ sector.toUpperCase() }}</h2>
            <member-list :masonry="true"
                          :documents="getFilteredCompanies(companies[sector])"
                          :logos_url="logos_url"
                          :queryStems="queryStems" />
          </div>
        </div>
      </div>
      <div v-if="!onMainPage">
        <member-list :masonry="false"
                      :documents="resultData['bloxmatch_external']"
                      :logos_url="logos_url"
                      :queryStems="queryStems"
                      :moreAvailable="moreAvailable"
                      :showLoader="showLoader"
                      @loadMore="loadMore" />
      </div>

    </div>
  </div>
</template>

<script>

import Labels from '../labels';
import axios from 'axios';
import MemberList from '@/components/MemberList';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'

library.add(faHome, faArrowAltCircleLeft)


export default {
  name: 'SearchPage',
  components: {
    MemberList,
    FontAwesomeIcon
  },
  data() {
    return {
      ALL_SECTORS_LABEL: 'All sectors',
      showInfoNoResults: false,
      showInfoEmptyQuery: false,
      showInfoUnableToConnect: false,
      lang: 'en',
      labels: {},
      query: '',
      showLoader: false,
      qs: require('qs'),
      sectors: [],
      companies: [],
      selectedSector: null,
      onlyResidents: false,
      logos_url: process.env.VUE_APP_LOGOS_URL,
      showInfoEmptyQuery: false,
      onMainPage: true,
      resultData: {},
      queryStems: [],
      moreAvailable: false,
    }
  },
  watch: {
    onlyResidents: function (newValue, oldValue) {
      this.submitNewQuery(true)

      if (this.onMainPage) {
        this.showInfoEmptyQuery = false
      }
    },
    selectedSector: function (newValue, oldValue) {
      if (this.onMainPage) {
        this.showInfoEmptyQuery = false
      }
    }
  },
  created () {
    this.labels = Labels.LABELS
    this.initPage()
    this.loadSectors()
  },
  methods: {
    backToMain() {
      this.initPage()
    },
    filterCompany(company) {
      return !this.onlyResidents || company.metadata.resident;
    },
    getFilteredCompanies(companies) {
      if (companies) {
        return companies.filter(this.filterCompany);
      }
      return []
    },
    initPage() {

      this.showInfoNoResults = false
      this.showInfoEmptyQuery = false
      this.showInfoUnableToConnect = false

      this.resultData = {}
      this.queryStems = []
      this.moreAvailable = false

      this.selectedSector = this.ALL_SECTORS_LABEL
      this.onlyResidents = false
      this.query = ''
      this.onMainPage = true

    },
    loadSectors() {
      var params = {}
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'values',
        data: this.qs.stringify(params, { indices: false }),
      })
      .then(response => {
        this.sectors = response.data['sector[includes]']
        this.sectors.unshift(this.ALL_SECTORS_LABEL)
        this.selectedSector = this.ALL_SECTORS_LABEL
        this.submitQueryAll()
      })
      .catch(e => {
        this.showInfoUnableToConnect = true
        console.log('Error receiving values data', e)
      })
    },

    submitQueryAll() {

      this.companies = []
      this.showLoader = true

      axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'documents/bloxmatch_external'
      })
      .then(response => {

        // console.log('response', response.data)

        this.companies = response.data
        this.showLoader = false
      })
      .catch(e => {
        console.log('Error receiving data', e)
        this.showLoader = false
      })
    },

    submitNewQuery(fromFilters) {
      if (!this.onMainPage || !fromFilters) {
        this.submitQuery(0, fromFilters)
      }
    },

    submitQuery(offset, fromFilters) {

      if (this.query != '') {
        this.queryBackend(this.query, offset)
      }
      else {
        this.showInfoEmptyQuery = true
      }

    },

    queryBackend(query, offset) {

      this.showLoader = true

      let params = { 'q': query.trim() }

      if (this.selectedSector !== this.ALL_SECTORS_LABEL) {
        params['sector[includes]'] = this.selectedSector
      }
      if (this.onlyResidents) {
        params['resident[includes]'] = true
      }

      params['limit'] = 5
      params['offset'] = offset

      axios({
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'search',
        data: this.qs.stringify(params, { indices: false }),
      })
      .then(response => {

        // console.log('response', response.data)

        if (offset == 0) {
          this.resultData = {}
          this.resultData = response.data.results
        }
        else {
          for (var i=0; i < response.data.results.bloxmatch_external.length; i++) {
            this.resultData.bloxmatch_external.push(response.data.results.bloxmatch_external[i])
          }
        }

        this.queryStems = []
        for (const stemLang in response.data.query_info.search_stems) {
          this.queryStems = this.queryStems.concat(response.data.query_info.search_stems[stemLang])
        }

        this.queryStems = [...new Set(this.queryStems)];  // remove duplicates
        this.moreAvailable = response.data.more_available.bloxmatch_external
        this.onMainPage = false
        this.showLoader = false

        // console.log('resultData', this.resultData)

        this.showInfoEmptyQuery = false
        this.showInfoUnableToConnect = false
        this.showInfoEmptyClasses = false

        let results_empty = true

        for (var key in this.resultData) {
          if (this.resultData[key].length > 0) {
            results_empty = false
            break
          }
        }
        if (results_empty == true) {
          this.showInfoNoResults = true
        }
        else {
          this.showInfoNoResults = false
        }

      })
      .catch(e => {
        this.showInfoUnableToConnect = true
        console.log('Error receiving search results', e)
        this.showLoader = false
      })
    },

    loadMore(offset) {
      this.submitQuery(offset, false)
    },


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/variables.scss";

#bloxmatch {

  .all-results-hidden {
    // use this for faster rendering
    display: none;
  }

  .bloxmatch-content {
    background: white;
  }

  .loading {
    opacity: 0.5;
  }

  .bloxmatch-page {

    background: $color-background;
    color: $color-text-default;
    padding: 1.25em 2em;

    h2 {
      font-size: 1.75em;
      font-weight: bold;
      margin-top: 0.5em;
      margin-bottom: 0.25em;
      text-align: center;
    }

    .search-header {

      .residents-filter {
        margin-top: 1em;
        margin-bottom: 0.5em;
      }

      .home-container {
        width: $home-container-width;
        display: flex;
        align-items: center;
      }

      .home-icon {
        width: $input-field-height;
        height: $input-field-height;
        color: #AAA;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .home-icon:hover {
        cursor: pointer;
      }

      .search-input-group {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        border-width: 0.0625em;
        border-color: $color-very-light-gray;
        border-style: solid;
        background: white;
        height: $input-field-height;

        #search-button {
          width: 60px;
          margin-left: 1px;
          display: flex;
          justify-content: center;

          img {
            height: 30px;
            width: 30px;
          }

        }
        #search-button:hover {
          cursor: pointer;
        }

        .input-field {
          border: none;
          height: $input-inner-component;
          font-size: 1em;
          background: white;
          color: $color-text-default;
          height: $input-field-height - 0.0625em - 0.0625em;
        }

        .input-field:focus {
          box-shadow: none;
          outline: 0 none;
        }
      }

      .search-input-group:focus-within {
        box-shadow: 0 0 1px 1px $color-bloxmatch-green-light;
      }

      .single-row-header {

        display: flex;

        .search-input-group {
          // width: 90%;
          max-width: $search-input-max-width;
        }

        .filters {
          border: none;
          border-radius: 0;
          margin: 0;
          margin-left: 1px;
          border-left: 1px solid $color-very-light-gray;
          padding-left: 1em;
          max-width: 10em;
          font-size: 1em;
          color: $color-text-default;
          height: $input-field-height - 0.0625em - 0.0625em;
          padding-right: 1.5em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .filters:focus-within {
          box-shadow: none;
        }
      }

      .multi-row-header {

        .home-container {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .filters {
          border-radius: 0;
          margin-left: 1px;
          font-size: 1em;
          color: $color-text-default;
          height: $input-field-height - 0.0625em - 0.0625em;
        }
        .filters:focus-within {
          box-shadow: 0 0 1px 1px $color-bloxmatch-green-light;
          border: none;
        }

      }

      .search-header-medium {
        .residents-filter {
          margin-left: $input-field-height+1em;
        }
      }

      .search-header-small {
        display: none;  // initially hidden
        width: 100%;
      }

    }

    .spinner-container {
      width: $input-field-height;
      height: $input-field-height;
      display: flex;
      align-items: center;
      justify-content: center;

      .spinner {
        width: 26px;
        height: 26px;
        border-top-color: $color-very-light-gray;
        border-left-color: $color-very-light-gray;
        border-bottom-color: $color-very-light-gray;
      }
    }

    .alert {
      margin-top: 1em;
      width: 100%;
      max-width: $search-input-max-width+$home-container-width;
      text-align: center;
    }

  }
}

@media screen and (max-width: 690px) {
  #bloxmatch {
    .bloxmatch-page {

      padding: 1.25em 0.5em;

      .search-header {
        .search-header-medium {
          display: none;
        }
        .search-header-small {
          display: block;
        }
      }
    }
  }
}


</style>
