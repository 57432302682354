<template>
  <div id="bloxmatch">
    <SearchPage />
  </div>
</template>

<script>
import SearchPage from './components/SearchPage.vue'

export default {
  name: 'App',
  components: {
    SearchPage
  }
}
</script>
