<template>

  <div class="bloxmatch-card">

    <div class="top-row">
      <div class="sector-container">{{ computedSector }}</div>
    </div>

    <div v-if="document.metadata.logo && !logoFailed" class="logo-container">
      <img class="member-logo" :src="logos_url + document.metadata.logo" @error="logoLoadError" />
    </div>
    <div v-else class="logo-container">
      [Waiting for logo]
    </div>

    <div class="member-name" v-bind:class="{'member-name-preview': !expanded}" v-html="document.metadata.name"></div>

    <div class="details-container">

      <div class="interests" v-bind:class="{'interests-preview': !expanded}">
        <div class="sub-label">Main interests</div>
        <div class="interest-row">
          <span v-if="document.metadata.main_interests">{{ document.metadata.main_interests }}</span>
          <span v-else class="no-interest">-</span>
        </div>
        <!-- <div class="sub-label">Sub interests</div>
        <div class="interest-row">
          <span v-if="document.metadata.sub_interests">{{ document.metadata.sub_interests }}</span>
          <span v-else class="no-interest">-</span>
        </div> -->
      </div>

      <b-collapse v-model="expanded">
        <div class="sub-label">Business information</div>
        <div>{{ document.metadata.virk.address }}</div>
        <div>{{ document.metadata.virk.phone }}</div>
        <div class="member-url"><b-link :href="document.metadata.url" target="_blank">{{ document.metadata.url }}</b-link></div>
        <div><a :href="'mailto:' + document.metadata.virk.email">{{ document.metadata.virk.email }}</a></div>

        <div class="mt-3">CVR: {{ document.metadata.cvr }}</div>
        <div v-if="document.metadata.virk.start_date">Est: {{ document.metadata.virk.start_date }}</div>
        <div>{{ document.metadata.virk.company_form }}</div>
        <div>{{ document.metadata.virk.main_industry }}</div>
      </b-collapse>

      <div class="arrows-row" @click="expanded = !expanded" :class="expanded ? null : 'collapsed'" :aria-expanded="expanded ? 'true' : 'false'">
        <div class="arrows up"><span></span><span></span></div>
        <div class="arrows down"><span></span><span></span></div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'MemberCard',
  props: {
    document: {
      type: Object,
    },
    logos_url: {
      type: String,
    },
  },
  data() {
    return {
      logoFailed: false,
      expanded: false
    }
  },
  computed: {
    computedSector: function() {
      if (this.document.metadata.sector && !this.document.metadata.sector.startsWith('[')) {
        return this.document.metadata.sector.toUpperCase()
      }
      else {
        // Hide special labels like "[sector missing]"
        return ''
      }
    }
  },
  methods: {
    logoLoadError() {
      this.logoFailed = true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/variables.scss";

#bloxmatch {

  .bloxmatch-card {
    border-radius: 4px;
    background: #FFFFFF;
    padding: 0.75em 1em;
  }

  .top-row {
    .sector-container {
      color: $color-gray;
      font-size: 0.9em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 1.5em;
    }
  }

  .logo-container {
    height: $logo-height;
    display: flex;
    align-items: center;
    color: #CCCCCC;
    font-size: 0.95em;
    margin: 1em 0;

    .member-logo {
      padding: 0;
      max-height: 100%;
      max-width: 100%
    }
  }

  .member-name {
    font-size: 1em;
    font-weight: 600;  // semi-bold
  }

  .member-name-preview {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .details-container {

    font-size: 0.95em;

    .interests {
      line-height: 1.5;
      .sub-label {
        min-height: 1.5em;
      }
      .interest-row {
        min-height: 1.5em;
        .no-interest {
          color: $color-light-gray;
          margin-left: 0.25em;
        }
      }
    }

    .interests-preview {
      .interest-row {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .member-url {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .arrows-row {
      display: flex;
      justify-content: center;
      padding-top: 1em;
    }
    .arrows-row:hover {
      cursor: pointer;
      span {
        border-color: $color-gray;
      }
    }
    .arrows-row.collapsed {
      .up {
        display: none;
      }
    }
    :not(.collapsed).arrows-row {
      .down {
        display: none;
      }
    }
    .arrows {
      span{
        display: block;
        width: 10px;
        height: 10px;
        border-bottom: 2px solid $color-very-light-gray;
        border-right: 2px solid $color-very-light-gray;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-top: -4px;
      }
    }
    .up {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }

}
</style>
