<template>

  <div class="card">

    <div class="card-top">
      <div class="card-left">
        <div class="member-sector">{{ computedSector }}</div>
      </div>

      <div class="card-right">

        <div class="quality-container">
          <div class="percentage-bar-container">
            <div class="percentage-bar" v-bind:style="{ width: 100*document.quality + '%', background: percentageBarColor }"></div>
          </div>
          <div v-b-popover.hover.right="{ customClass: 'bloxmatch-popover', content: 'Match relevance indicator' }" class="icon"><font-awesome-icon :icon="['far', 'question-circle']"></font-awesome-icon></div>
        </div>

      </div>
    </div>

    <div class="card-content">

      <div class="card-left">

        <div v-if="document.metadata.logo && !logoFailed">
          <img class="member-logo" :src="logos_url + document.metadata.logo" @error="logoLoadError" />
        </div>
        <div v-else>
          <div class="no-logo-container">[Waiting for logo]</div>
        </div>

        <div class="business-info">
          <div>{{ document.metadata.virk.address }}</div>
          <div>{{ document.metadata.virk.phone }}</div>
          <div><b-link :href="document.metadata.url" target="_blank">{{ document.metadata.url }}</b-link></div>
          <div><a :href="'mailto:' + document.metadata.virk.email">{{ document.metadata.virk.email }}</a></div>

          <div class="mt-3">CVR: {{ document.metadata.cvr }}</div>
          <div v-if="document.metadata.virk.start_date">Est: {{ document.metadata.virk.start_date }}</div>
          <div>{{ document.metadata.virk.company_form }}</div>
          <div>{{ document.metadata.virk.main_industry }}</div>
        </div>

      </div>

      <div class="card-right">

        <div class="title-row">
          <div class="member-name padded-container">{{ document.metadata.name }}</div>
        </div>

        <div v-if="document.metadata.main_interests" class="padded-container">
          <div class="sub-label">Main interests</div>
          <span v-if="highlighted_main_interests" v-html="highlighted_main_interests"></span>
          <span v-else>{{ document.metadata.main_interests }}</span>
        </div>

        <!-- <div v-if="document.metadata.sub_interests" class="padded-container">
          <div class="sub-label">Sub interests</div>
          <span v-if="highlighted_sub_interests" v-html="highlighted_sub_interests"></span>
          <span v-else>{{ document.metadata.sub_interests }}</span>
        </div> -->

        <div class="padded-container card-section-divider"></div>

        <div v-if="Object.keys(website_preview).length > 0" class="url-sentence-container">
          <b-link :href="website_preview.url" target="_blank">
            <div class="sub-label">{{ truncateStr(website_preview.title, 80) }}</div>
            <div v-html="website_preview.text"></div>
          </b-link>
        </div>
        <div v-else class="url-sentence-container url-sentence-not-found">
          <div class="website-empty-info">Did not find any content from the company webpage that matches the given query.</div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

library.add(faQuestionCircle)

export default {
  name: 'MemberCardResult',
  components: {
    FontAwesomeIcon
  },
  props: {
    document: {
      type: Object,
    },
    logos_url: {
      type: String,
    },
    queryStems: {
      type: Array,
    },
  },
  data() {
    return {
      logoFailed: false,
      highlighted_texts: {},
      website_preview: {},
      highlighted_main_interests: '',
      highlighted_sub_interests: '',
    }
  },

  computed: {
    percentageBarColor: function() {
      if (this.document.quality >= 0.65) {
        return '#3cc93f'
      }
      else if (this.document.quality >= 0.4) {
        return '#ffcb4c'
      }
      else {
        return '#cc27b2'
      }
    },
    computedSector: function() {
      if (this.document.metadata.sector && !this.document.metadata.sector.startsWith('[')) {
        return this.document.metadata.sector.toUpperCase()
      }
      else {
        // Hide special labels like "[sector missing]"
        return ''
      }
    }
  },
  watch: {
    queryStems: function (newValue, oldValue) {
      this.highlightTexts()
    }
  },
  created () {
    this.highlightTexts()

  },
  methods: {

    highlightTexts() {
      this.highlighted_main_interests = this.highlightTextFromTokens(this.queryStems, this.document.metadata.main_interests_tokens)
      this.highlighted_sub_interests = this.highlightTextFromTokens(this.queryStems, this.document.metadata.sub_interests_tokens)
      this.website_preview = this.getWebsitePreview(this.queryStems)
    },

    highlightTextFromTokens(queryStems, text_tokens) {

      // Collect all indices that match to any of the query stems
      var matchingIndices = []

      for (const queryStem of queryStems) {
        if (text_tokens.stems.hasOwnProperty(queryStem)) {
          matchingIndices = matchingIndices.concat(text_tokens.stems[queryStem].indices)
        }
      }

      // Highlight based on indices
      return this.highlightTextFromIndices(text_tokens.original_tokens, matchingIndices)
    },

    // Highlight tokens in indices
    highlightTextFromIndices(original_tokens, indices) {

      var text = ''

      for (var i=0; i < original_tokens.length; i++) {
        if (indices.includes(i)) {
          text = text + '<span class="highlighted-query-term">' + original_tokens[i] + '</span>'
        }
        else {
          text = text + original_tokens[i]
        }
      }

      return text
    },

    getWebsitePreview(queryStems) {

      var preview = {}
      var maxMatches = 0
      var bestSentence = []
      var bestMatchingIndices = []

      for (var i=0; i < this.document.metadata.website_pages.length; i++) {
        for (var j=0; j < this.document.metadata.website_pages[i].sentences.length; j++) {
          var sentence_stems = this.document.metadata.website_pages[i].sentences[j].stems
          var sentenceMatches = 0
          var matchingIndices = []
          var foundQueryStemMatches = []

          for (const queryStem of queryStems) {
            if (sentence_stems.hasOwnProperty(queryStem)) {
              sentenceMatches = sentenceMatches+sentence_stems[queryStem].count
              matchingIndices = matchingIndices.concat(sentence_stems[queryStem].indices);

              // Give bonus for finding multiple different query stems
              if (!foundQueryStemMatches.includes(queryStem)) {
                sentenceMatches = sentenceMatches + 10
              }
            }
          }

          if (sentenceMatches > maxMatches) {
            maxMatches = sentenceMatches
            bestSentence = [i, j]
            bestMatchingIndices = [...new Set(matchingIndices)]
          }

        }
      }

      if (maxMatches > 0) {
        var sentence_tokens = this.document.metadata.website_pages[bestSentence[0]].sentences[bestSentence[1]].original_tokens
        preview['title'] = this.document.metadata.website_pages[bestSentence[0]].title
        preview['url'] = this.document.metadata.website_pages[bestSentence[0]].url
        preview['text'] = this.highlightTextFromIndices(sentence_tokens, bestMatchingIndices)
      }

      return preview
    },

    truncateStr(str, l) {
      if (str.length > l) {
        return str.substring(0, l-3) + '...'
      }
      return str
    },
    logoLoadError() {
      this.logoFailed = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/variables.scss";

#bloxmatch {

  .padded-container {
    padding: 0 14px;
  }

  .card-section-divider {
     border-top: 1px solid #DDD;
     margin: 1em 1.25em;
  }

  .card {
    border: 2px solid $color-very-light-gray;
    border-radius: 5px;
    padding: 1em 4em;
    text-align: left;

    .card-top {
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .card-left {
        width: 80%;
      }

      .card-right {
        margin-left: 1em;
        width: 20%;
      }
    }

    .card-content {
      display: flex;
      flex-direction: row;

      .card-left {
        width: 36%;
      }

      .card-right {
        margin-left: 1em;
        width: 64%;
      }
    }

    .quality-container {
      float: right;
      display: flex;
      width: 100%;
      min-width: 40px;
      max-width: 160px;

      .percentage-bar-container {
        width: 100%;
        float: right;

        .percentage-bar {
          height: 8px;
          min-width: 8px;
          border-radius: 4px;
          float: right;
        }
      }

      .icon {
        width: 1em;
        height: 1em;
        margin-left: 0.5em;
        color: $color-dark-gray;
        margin-top: -10px;
      }
    }
  }

  .member-logo {
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 55%;
    max-height: 18em;
  }

  .no-logo-container {
    display: flex;
    align-items: center;
    color: #CCCCCC;
    font-size: 0.95em;
    height: 150px;
  }

  .info-container {
    border: 1px solid $color-very-light-gray;
    font-size: 0.95em;
    border-radius: 5px;
    padding: 0.5em 0.5em;
    margin: 0.125em 0;
  }

  .url-sentence-container {
    padding: 0px 14px 8px 14px;
    border: 1px solid white;
    border-radius: 5px;
    margin-top: -0.5em;

    a {
      color: $color-text-default !important;
      text-decoration: none !important;
    }

    .website-empty-info {
      color: $color-dark-gray;
      padding-top: 8px;
    }

  }

  .url-sentence-container:hover {
    cursor: pointer;
    border-color: #eee;
  }
  .url-sentence-not-found:hover {
    cursor: default;
  }

  .keywords-container {

    display: flex;
    flex-wrap: wrap;
    margin: 1em 0em;
    padding: 0 1em;

    .keyword {
      border: 1px solid lightgray;
      border-radius: 0px;
      padding: 0.125em 0.75em;
      margin: 0.125em 0.125em;
    }

  }

  .loading-container {
    color: $color-gray;
  }

  .business-info {
    font-size: 0.9em;
    color: $color-dark-gray;

    a {
      color: $color-dark-gray;

    }
  }

  .spinner {
    margin-left: 0.25em;
    width: 1.25em;
    height: 1.25em;
    border-top-color: $color-very-light-gray;
    border-left-color: $color-very-light-gray;
    border-bottom-color: $color-very-light-gray;
    border-width: 3px;
  }

  .title-row {
    display: flex;
    justify-content: space-between;

    .member-name {
      font-size: 1.75em;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 0.5em;
      // padding: 0 1em;
      width: 100%;
    }

    // .quality-container {
    //
    //   float: right;
    //   display: flex;
    //   width: 30%;
    //   min-width: 40px;
    //   max-width: 160px;
    //
    //   .percentage-bar-container {
    //     width: 100%;
    //     float: right;
    //
    //     .percentage-bar {
    //       height: 8px;
    //       min-width: 8px;
    //       border-radius: 4px;
    //       float: right;
    //     }
    //   }
    //
    //   .icon {
    //     width: 1em;
    //     height: 1em;
    //     margin-left: 0.5em;
    //     color: $color-dark-gray;
    //     margin-top: -9px;
    //   }
    //
    // }
  }

  @media only screen and (max-width: 999px) {
    .card {
      padding: 1em 2em;
    }
  }

  @media only screen and (max-width: 690px) {
    .card {
      padding: 1em 1em;

      .card-content {

        flex-direction: column-reverse;

        .card-left {
          width: 100%;
        }

        .card-right {
          margin-left: 0;
          width: 100%;
        }

        .padded-container {
          padding: 0;
        }

        .url-sentence-container {
          padding: 0;
        }

      }

      .icon {
        display: none !important;
      }

    }
  }

}
</style>
