const LABELS = {
  'en': {
    'filters': 'Filters',
    'notification_unable_to_connect': 'Unable to connect to the server. Please try again later.',
    'notification_empty_query': 'Please type in a search query.',
    'notification_no_results': 'No results found. Try again with a different search query and/or filters.',
    'inputPlaceholder': 'Enter search text',
  },
  'title': 'Bloxmatch',
}

export default {
  LABELS: LABELS,
}
