<template>
  <div class="bloxmatch-grid">
    <div v-if="masonry">
      <masonry :cols="{default: 5, 1440: 4, 1200: 3, 999: 2, 690: 1}" :gutter="{default: '6px'}">
        <div v-for="(doc, index) in documents" :key="index" class="card-container">
          <member-card :document="doc" :logos_url="logos_url" />
        </div>
      </masonry>
    </div>
    <div v-else class="results-list">
      <div v-for="(doc, index) in documents" :key="index" class="card-container">
        <member-card-result :document="doc" :logos_url="logos_url" :queryStems="queryStems" />
      </div>
    </div>

    <div v-if="moreAvailable" class="load-more">
      <b-button variant="outline-secondary" @click="loadMore()" :disabled="showLoader" >
        <b-spinner v-if="showLoader" class="spinner"></b-spinner>
        <span v-else>Load more results</span>
      </b-button>
    </div>

  </div>
</template>

<script>

import Vue from 'vue';
import MemberCard from '@/components/MemberCard';
import MemberCardResult from '@/components/MemberCardResult';
import VueMasonry from 'vue-masonry-css';
Vue.use(VueMasonry);

export default {
  name: 'MemberList',
  components: {
    MemberCard,
    MemberCardResult
  },
  props: {
    masonry: {
      type: Boolean,
    },
    documents: {
      type: Array,
    },
    moreAvailable: {
      type: Boolean,
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    queryStems: {
      type: Array,
    },
    logos_url: {
      type: String,
    }
  },
  data() {
    return {
    }
  },
  created () {
  },
  methods: {
    loadMore() {
      this.$emit('loadMore', this.documents.length)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import "../assets/css/variables.scss";


#bloxmatch {

  .bloxmatch-grid {
    .card-container {
      margin: 6px 0;
    }

    .load-more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2em;
      font-size: 1em !important;
      text-decoration: none !important;

      button {
        min-width: 200px;
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .spinner {
        width: 22px;
        height: 22px;
        border-top-color: gray;
        border-left-color: gray;
        border-bottom-color: gray;
      }
    }

  }

}

</style>
